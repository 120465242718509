import React from 'react';

/**
 * @param {import('./types').VectorAsset} 
 */
function Stars({ props, fill, stroke }) {
  fill = fill || '#E1E1E1';
  stroke = stroke || '#371A45';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path d="M12 17c.8-4.2 1.9-5.3 6.1-6.1.5-.1.8-.5.8-1s-.3-.9-.8-1C13.9 8.1 12.8 7 12 2.8c-.1-.5-.5-.8-1-.8s-.9.3-1 .8C9.2 7 8.1 8.1 3.9 8.9c-.4.1-.8.5-.8 1s.3.9.8 1c4.2.8 5.3 1.9 6.1 6.1.1.5.5.8 1 .8s.9-.4 1-.8zM22 24c-2.8-.6-3.4-1.2-4-4-.1-.5-.5-.8-1-.8s-.9.3-1 .8c-.6 2.8-1.2 3.4-4 4-.5.1-.8.5-.8 1s.3.9.8 1c2.8.6 3.4 1.2 4 4 .1.5.5.8 1 .8s.9-.3 1-.8c.6-2.8 1.2-3.4 4-4 .5-.1.8-.5.8-1s-.4-.9-.8-1zM29.2 14c-2.2-.4-2.7-.9-3.1-3.1-.1-.5-.5-.8-1-.8s-.9.3-1 .8c-.4 2.2-.9 2.7-3.1 3.1-.5.1-.8.5-.8 1s.3.9.8 1c2.2.4 2.7.9 3.1 3.1.1.5.5.8 1 .8s.9-.3 1-.8c.4-2.2.9-2.7 3.1-3.1.5-.1.8-.5.8-1s-.3-.9-.8-1zM5.7 22.3c-.3-.3-.7-.4-1.1-.2-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3-.1.1-.1.3-.1.4s0 .3.1.4c.1.1.1.2.2.3.1.1.2.2.3.2.1.1.3.1.4.1.1 0 .3 0 .4-.1s.2-.1.3-.2c.1-.1.2-.2.2-.3.1-.1.1-.3.1-.4s0-.3-.1-.4c0-.1-.1-.2-.2-.3zM28 7c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.1-.1-.2-.2-.3-.2-.2-.1-.5-.1-.8 0-.1 0-.2.1-.3.2-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3z" fill={fill} stroke={stroke} strokeWidth="1.5"></path>
    </svg>
  );
}

export default Stars;