import React from 'react';

/**
 * @param {import('./types').VectorAsset} 
 */
function BlackHole({ props, fill, stroke })  {
  fill = fill || 'url(#default-gradient)';
  stroke = stroke || '#6c757d';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198 198" {...props}>
      <defs>
        <radialGradient id="default-gradient">
          <stop offset="45%" stopColor="#03071e" />
          <stop offset="90%" stopColor="#6c757d" />
        </radialGradient>
      </defs>
      <path d="M192.384 99.825c-9.361-22.131-28.78-38.577-51.252-46.075a96.775 96.775 0 0116.379-.688c9.065.395 17.464 2.395 25.745 5.045 5.602 1.793 9.756-5.12 5.588-9.271-6.584-6.556-14.508-11.75-23.228-15.286-22.268-9.029-47.629-6.927-68.82 3.66a96.704 96.704 0 0111.095-12.067c6.689-6.13 14.043-10.656 21.772-14.637 5.229-2.694 3.278-10.519-2.604-10.507-9.292.02-18.568 1.95-27.234 5.616-22.13 9.362-38.577 28.781-46.075 51.253a96.671 96.671 0 01-.687-16.379c.395-9.065 2.395-17.464 5.045-25.745 1.793-5.602-5.12-9.756-9.271-5.588-6.556 6.584-11.75 14.508-15.286 23.228-9.029 22.268-6.927 47.629 3.66 68.82a96.704 96.704 0 01-12.067-11.095c-6.13-6.689-10.656-14.042-14.637-21.772C7.813 63.107-.012 65.059 0 70.941c.02 9.292 1.95 18.568 5.616 27.234 9.361 22.131 28.78 38.577 51.251 46.075a96.664 96.664 0 01-16.378.687c-9.065-.395-17.464-2.395-25.745-5.045-5.602-1.793-9.756 5.12-5.588 9.271 6.584 6.556 14.508 11.75 23.228 15.286 22.268 9.029 47.629 6.927 68.82-3.66a96.704 96.704 0 01-11.095 12.067c-6.689 6.13-14.042 10.656-21.772 14.637-5.229 2.694-3.278 10.519 2.604 10.507 9.292-.02 18.568-1.95 27.234-5.616 22.131-9.361 38.577-28.781 46.075-51.252a96.671 96.671 0 01.687 16.379c-.395 9.065-2.395 17.464-5.045 25.745-1.793 5.602 5.12 9.756 9.271 5.588 6.556-6.584 11.75-14.509 15.286-23.228 9.029-22.268 6.927-47.629-3.66-68.82a96.704 96.704 0 0112.067 11.095c6.13 6.689 10.656 14.042 14.637 21.772 2.694 5.229 10.519 3.278 10.507-2.604-.02-9.292-1.95-18.568-5.616-27.234zM99 122.917c-13.209 0-23.917-10.708-23.917-23.917S85.791 75.083 99 75.083 122.917 85.791 122.917 99 112.209 122.917 99 122.917z" fill={fill} stroke={stroke} strokeWidth="4"></path>
    </svg>
  );
}

export default BlackHole;
