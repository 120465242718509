import React from 'react';

/**
 * @param {import('./types').VectorAsset 
 * & {kind: '5-corner' | 'shine' | 'circle'}} 
 */
function Star({ props, fill, stroke, kind }) {
  fill = fill || '#E1E1E1';
  stroke = stroke || '#371A45';
  switch (kind) {
    case '5-corner':
      return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"  {...props}>
        <path d="M48.856 22.73a3.56 3.56 0 00.906-3.671 3.56 3.56 0 00-2.892-2.438l-12.092-1.757a1.58 1.58 0 01-1.19-.865L28.182 3.043a3.56 3.56 0 00-3.212-1.996 3.56 3.56 0 00-3.211 1.996L16.352 14c-.23.467-.676.79-1.191.865L3.069 16.622A3.56 3.56 0 00.177 19.06a3.56 3.56 0 00.906 3.671l8.749 8.528c.373.364.544.888.456 1.4L8.224 44.701a3.506 3.506 0 00.781 2.904c1.066 1.267 2.927 1.653 4.415.871l10.814-5.686a1.619 1.619 0 011.472 0l10.815 5.686a3.544 3.544 0 001.666.417c1.057 0 2.059-.47 2.748-1.288a3.505 3.505 0 00.781-2.904l-2.065-12.042a1.582 1.582 0 01.456-1.4l8.749-8.529z" fill={fill} stroke={stroke} strokeWidth="5"></path>
      </svg>);
    case 'shine':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  {...props}>
          <path d="M505.445 256.177l-175.194-36.98 48.29-74.274a8.266 8.266 0 00-1.234-10.48c-2.871-2.742-7.29-3.032-10.524-.718l-68.29 49.27L255.768 6.318c-.92-3.831-4.428-6.464-8.371-6.31a8.254 8.254 0 00-7.815 6.996l-26.976 175.319-67.355-48.597a8.255 8.255 0 00-11.75 11.198l49.395 75.984L6.638 256.16a8.262 8.262 0 00-6.637 8.238 8.263 8.263 0 006.919 8.008l170 27.919-43.419 66.786a8.255 8.255 0 001.226 10.48c2.871 2.742 7.29 3.04 10.524.718l72.976-52.653 37.952 179.79a8.257 8.257 0 008.081 6.552c.065 0 .129 0 .202-.004a8.256 8.256 0 007.952-6.939l28.137-174.537 66.234 47.79c3.226 2.318 7.645 2.032 10.524-.718a8.266 8.266 0 001.234-10.48l-47.177-72.565 173.419-22.097a8.26 8.26 0 007.21-7.859 8.267 8.267 0 00-6.55-8.412zm-189.218 23.649a8.262 8.262 0 00-5.888 12.694l30.25 46.524-41.234-29.75c-2.323-1.677-5.363-2.036-7.992-.931a8.273 8.273 0 00-4.992 6.315l-23.202 143.928-31.401-148.776a8.27 8.27 0 00-8.081-6.553 8.26 8.26 0 00-4.831 1.561l-47.411 34.206 26.218-40.319a8.28 8.28 0 00.629-7.835 8.265 8.265 0 00-6.218-4.814L54.356 263.459l143.726-28.746a8.258 8.258 0 005.307-12.597l-31.944-49.125 42.444 30.621a8.226 8.226 0 008.024.92 8.262 8.262 0 004.968-6.363L249.55 50.846l35.782 147.98a8.257 8.257 0 0012.854 4.758l42.411-30.597-30.757 47.303a8.259 8.259 0 005.225 12.58l139.153 29.375-137.991 17.581z" stroke={fill} strokeWidth="32"></path>
        </svg>
      );
    case 'circle':
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
          <circle cx="64" cy="64" r="56" 
          fill={fill} stroke={stroke} strokeWidth="12" />
        </svg>
      );
  }
}

export default Star;
